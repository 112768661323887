import React, {useState, useEffect, useRef} from 'react'
import { downloadBlob, handleErrors } from '../../utils'
import axios from '../../axios'
import moment from 'moment'
import { useAuthContext } from '../../auth'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, CircularProgress, Button, Table, TableHead, TableRow, TableCell, TableBody, TableSortLabel, TablePagination, Grid } from '@material-ui/core'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Form from '../form'
import Select from '../form/Select'
import * as Yup from 'yup'
import { caratRanges, clarities, colors, cuts, fluorescenceHash, fluorescences, polishes, shapeHash, shapes, symmetries } from '../../utils/dictionary'
import styled from 'styled-components'
import { round } from 'lodash'

const Wrapper = styled.div`
    .MuiSelect-root {
        min-width: 40px !important;
       
    }

    form div {
        margin-right: 10px;
    }
`

const TableWrapper = styled.div`
    .green {
        background-color: #c8e6c9;
    }
`

const bucketSchema = Yup.object().shape({
    type: Yup.string()
        .oneOf(['lab-grown', 'natural'], 'Invalid type')
        .required('Required'),
    certificate: Yup.string()
        .oneOf(['GIA', 'NONGIA'], 'Invalid certificate')
        .required('Required'),
    shape: Yup.string()
        .oneOf(shapes, 'Invalid shape')
        .required('Required'),
    color: Yup.string()
        .oneOf(colors, 'Invalid color')
        .required('Required'),
    clarity: Yup.string()
        .oneOf(clarities, 'Invalid clarity')
        .required('Required'),
    cut: Yup.string()
        .oneOf(cuts, 'Invalid cut')
        .required('Required'),
    polish: Yup.string()
        .oneOf(polishes, 'Invalid polish')
        .required('Required'),
    symmetry: Yup.string()
        .oneOf(symmetries, 'Invalid symmetry')
        .required('Required'),
    fluorescence: Yup.string()
        .oneOf(fluorescences, 'Invalid fluorescence intensity')
        .required('Required'),
    carat_range: Yup.string()
        .oneOf(caratRanges, 'Invalid carat range')
        .required('Required'),
})

const defaultValues = {
    type: 'natural',
    // type: 'lab-grown',
    certificate: 'GIA',
    // certificate: 'NONGIA',
    shape: 'RD',
    color: 'D',
    // color: 'E',
    clarity: 'FL',
    // clarity: 'VVS2',
    cut: 'ID',
    polish: 'EX',
    symmetry: 'EX',
    fluorescence: 'N',
    carat_range: '0.00_0.22'
    // carat_range: '1.10_1.19'
}

const formatCount = x => isNaN(x) ? x : round(x, 0)
const formatPct = x => {
    const count = formatCount(x)
    const sign = count > 0 ? '+' : ''
    return `${sign}${count}%`
}

const StatsTable = ({
    stats
}) => {
    const {
        count: xCount, min: xMin, max: xMax, 
        median: xMedian, min3: xMin3, min10: xMin10, suppliers=[],
        num_suppliers, rap, sites=[], delta
    } = stats

    const headers = [
        {id: 'id', label: 'Vendor', formatter: x=>x}, 
        {id: 'min', label: 'Cheapest'},
        ...delta ? [{id: 'minDelta', label: 'Delta', formatter: formatPct}] : [],
        {id: 'min3', label: 'Cheapest 3'},
        ...delta ? [{id: 'min3Delta', label: 'Delta', formatter: formatPct}] : [],
        {id: 'min10', label: 'Cheapest 10'},
        ...delta ? [{id: 'min10Delta', label: 'Delta', formatter: formatPct}] : [],
        {id: 'median', label: 'Median'},
        ...delta ? [{id: 'medianDelta', label: 'Delta', formatter: formatPct}] : [],
        // {id: 'max', label: 'Most Expensive'},
        // ...delta ? [{id: 'maxDelta', label: 'Delta'}] : [],
        {id: 'count', label: 'Number of Stones'},
    ].filter(x => x)

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState('min')

    useEffect(() => {
        setOrder('asc')
        setOrderBy('min')
        setPage(0)
    }, [stats])

    const handleChangePage = (_, newPage) => setPage(newPage)
    
    const handleChangeRowsPerPage = e => {
        setRowsPerPage(+e.target.value)
        setPage(0)
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
          return -1
        }
        if (b[orderBy] > a[orderBy]) {
          return 1
        }
        return 0
    }

    const getComparator = (order, orderBy) =>
        order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy)
      
    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index])
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0])
            if (order !== 0) return order
            return a[1] - b[1]
        })
        return stabilizedThis.map((el) => el[0])
    }

    const createSortHandler = (property) => (event) => handleRequestSort(event, property)

    return <TableWrapper>
        <h2>Stats</h2>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Number of Vendors</TableCell>
                    <TableCell>Rap Price</TableCell>
                    <TableCell>Cheapest</TableCell>
                    <TableCell>Cheapest 3</TableCell>
                    <TableCell>Cheapest 10</TableCell>
                    <TableCell>Median</TableCell>
                    <TableCell>Most Expensive</TableCell>
                    <TableCell>Number of Stones</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>{num_suppliers}</TableCell>
                    <TableCell>{formatCount(rap)}</TableCell>
                    <TableCell>{formatCount(xMin)}</TableCell>
                    <TableCell>{formatCount(xMin3)}</TableCell>
                    <TableCell>{formatCount(xMin10)}</TableCell>
                    <TableCell>{formatCount(xMedian)}</TableCell>
                    <TableCell>{formatCount(xMax)}</TableCell>
                    <TableCell>{formatCount(xCount)}</TableCell>
                </TableRow>
            </TableBody>
        </Table>

        <Table>
            <TableHead>
                <TableRow>
                    {headers.map(({id, label}) => <TableCell
                        sortDirection={orderBy === id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === id}
                            direction={orderBy === id ? order : 'asc'}
                            onClick={createSortHandler(id)}
                        >
                            {label === 'Vendor' ? 'Website': label}
                        </TableSortLabel>
                    </TableCell>)}
                </TableRow>
            </TableHead>
            <TableBody>
                {stableSort(sites, getComparator(order, orderBy))
                .map((x, i) => <TableRow key={i}>
                    {headers.map(({id, formatter=formatCount}) => <TableCell key={id}>{formatter(x[id])}</TableCell>)}
                </TableRow>)}
            </TableBody>
        </Table>
        
        <Table>
            <TableHead>
                <TableRow>
                    {headers.map(({id, label}) => <TableCell
                        sortDirection={orderBy === id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === id}
                            direction={orderBy === id ? order : 'asc'}
                            onClick={createSortHandler(id)}
                        >
                            {label}
                        </TableSortLabel>
                    </TableCell>)}
                </TableRow>
            </TableHead>
            <TableBody>
                {stableSort(suppliers, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((x, i) => <TableRow className={x.is_self ? 'green' : ''} key={i}>
                    {headers.map(({id, formatter=formatCount}) => <TableCell key={id}>{formatter(x[id])}</TableCell>)}
                </TableRow>)}
            </TableBody>
        </Table>
        <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={suppliers.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
        />
    </TableWrapper>
}

const BucketInfo = ({supplier}) => {
    const [bucketInfo, setBucketInfo] = useState()

    const handleSubmit = async ({
        type, certificate, shape, color, clarity, cut, polish, symmetry, fluorescence, carat_range
    }) => {
        const bucketId = [type === 'lab-grown', certificate, shape, color, cut, clarity, polish, symmetry, fluorescence, carat_range].join('-')
        try {
            const {data} = await axios.post('/listed-diamonds/bucket-info', {
                bucket: bucketId, supplier
            })
            setBucketInfo(data)
        } catch(err) {
            handleErrors({err})
        }
    }

    const methods = useForm({ 
        defaultValues, 
        resolver: yupResolver(bucketSchema) 
    })

    return <Wrapper>
        <h2 style={{marginTop: '40px'}}>Bucket Info</h2>
        <Form methods={methods} onSubmit={handleSubmit}>
            <Select 
                name="type"
                label="Type"
                options={[
                    {value: 'Natural', key: 'natural'},
                    {value: 'Lab Grown', key: 'lab-grown'},
                ]}
            />
            <Select
                name="certificate"
                label="Certificate Lab"
                options={[
                    {value: 'GIA', key: 'GIA'},
                    {value: 'Other', key: 'NONGIA'},
                ]}
            />
            <Select
                name="shape"
                label="Shape"
                options={Object.entries(shapeHash).map(([key, value]) => ({key, value}))}
            />
            <Select
                name="color"
                label="Color"
                options={colors.map(value => ({value, key: value}))}
            />
            <Select
                name="clarity"
                label="Clarity"
                options={clarities.map(value => ({value, key: value}))}
            />
            <Select
                name="cut"
                label="Cut"
                options={cuts.map(value => ({value, key: value}))}
            />
            <Select
                name="polish"
                label="Polish"
                options={polishes.map(value => ({value, key: value}))}
            />
            <Select
                name="symmetry"
                label="Symmetry"
                options={symmetries.map(value => ({value, key: value}))}
            />
            <Select
                name="fluorescence"
                label="Fluorescence"
                options={Object.entries(fluorescenceHash).map(([key, value]) => ({value, key}))}
            />
            <Select
                name="carat_range"
                label="Carat Range"
                options={caratRanges.map(value => ({value: value.replace('_', ' - '), key: value}))}
            />
            <br /><br />
            <Button
                type="submit"
                color="primary"
                variant="contained"
                loading={methods.formState.isSubmitting}
            >
                {methods.formState.isSubmitting ? <CircularProgress size={24} color="secondary" /> : 'Get Bucket Stats'}
            </Button>
        </Form>
        {bucketInfo && <StatsTable stats={bucketInfo} />}
    </Wrapper>
}

export default function ListedDiamonds() {
    const fileRef = useRef()
    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)
    const [loadingSuppliers, setLoadingSuppliers] = useState(false)
    const [loadingStockData, setLoadingStockData] = useState(false)
    const [loadingReport, setLoadingReport] = useState(false)
    const [suppliers, setSuppliers] = useState([])
    const [stockData, setStockData] = useState()
    const [selectedSupplier, setSelectedSupplier] = useState('')
    const {user} = useAuthContext()
    const {is_supplier, supplier_id} = user

    const getSuppliers = async() => {
        if (is_supplier) return
        setLoadingSuppliers(true)

        try {
            const {data} = await axios.get('/suppliers');
            setSuppliers(data.map(({supplier_id, name}) => ({supplier_id, name})))
        } catch (e) {

        } finally {
            setLoadingSuppliers(false)
        }
    }

    const getData = async() => {
        try {
            setLoading(true)
            const {data} = await axios.get(`/listed-diamonds?supplier=${selectedSupplier}`)
            setData(data)
        } catch(err) {
            handleErrors({err})
        } finally {
            setLoading(false)
        }
    }

    const getStockData = async() => {
        try {
            setLoadingStockData(true)
            let supplier = selectedSupplier || supplier_id
            const {data} = await axios.get(`https://api-server.newecx.com/api/private/vendor-stats?supplier_id=${supplier}&api_key=6wLwdwyOlYRJtvdXMMi61GyjHTp:ifz67aNFdNbiP4C5CWtfGCztqFe`)
            setStockData(data)
        } catch (error) {
            handleErrors({error})
        } finally {
            setLoadingStockData(false)
        }
    }

    const downloadReport = async () => {
        try {
            setLoadingReport(true)
            const {data} = await axios.post(`/listed-diamonds/report?supplier=${selectedSupplier}`)
            const blob = new Blob([data], { type: 'text/csv' })
            downloadBlob(blob, 'report.csv') 
        } catch(err) {
            handleErrors({err})
        } finally {
            setLoadingReport(false)
        }
    }

    const downloadReserve = async (api_key) => {
        try {
            setLoadingReport(true)
            const {data} = await axios.get(`https://apis.newecx.com/reservable-diamonds/worksheet?api_key=${api_key}`)
            const blob = new Blob([data], { type: 'text/csv' })
            downloadBlob(blob, 'report.csv') 
        } catch(err) {
            handleErrors({err})
        } finally {
            setLoadingReport(false)
        }
    }

    const uploadReserve = async (api_key) => {
        try {
            setLoadingReport(true)
            const file = fileRef.current.files[0]
            if (!file) 
                return alert('Please upload a file')
            const csv = await file.text()
            await axios.put(`https://apis.newecx.com/reservable-diamonds/worksheet?api_key=${api_key}`, csv)
            toast.success('Reserve uploaded successfully')
        } catch(err) {
            handleErrors({err})
        } finally {
            setLoadingReport(false)
        }
    }

    useEffect(() => {
        getSuppliers()
    }, [])

    useEffect(() => {
        if (!is_supplier && !selectedSupplier) {
            setData()
            setStockData()
            return
        }
        getData()
        getStockData()
    }, [selectedSupplier])

    return (
        <div>
            {!is_supplier && <Autocomplete
                style={{ width: 300, marginBottom: 20 }}
                getOptionLabel={(option) => option.name}
                options={suppliers}
                onChange={(_, opt) => setSelectedSupplier(opt ? opt.supplier_id : '')}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Supplier"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                            <React.Fragment>
                                {loadingSuppliers ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                            ),
                        }}
                    />
                )}
            />}
            {loading && <CircularProgress color="primary" size={40} style={{marginTop: 20}} />}
            {!loading && data &&
                <>
                    <Grid container spacing={3}>
                        {loadingStockData ? <CircularProgress color="primary" size={40} style={{marginTop: 20}} /> :
                            <Grid item xs={12} sm={6}>
                                <h2>Stock</h2>
                                Uploaded Diamonds: {stockData.total}<br />
                                Accepted Diamonds: {stockData.listed}<br />
                                {/* Rejected Diamonds: {data.rejected}<br /> */}
                                Retrieved Images: {stockData.images}<br />
                                Retrieved Videos: {stockData.videos}<br />
                                Last Uploaded At: {moment(stockData.created_at).format('MM/DD/YYYY h:mm A')}<br /><br />
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    onClick={downloadReport}
                                    style={{width: "200px"}}
                                >
                                    {loadingReport ? <CircularProgress size={24} color="secondary" /> : 'Download Report'} 
                                </Button>
                            </Grid>
                        }
                        <Grid item xs={12} sm={6}>
                            <h2>Orders</h2>
                            Available: {data.avail_count} ({data.avail_pct}%)<br />
                            Cancelled: {data.cancelled_count} ({data.cancelled_pct}%)<br />
                            Total: {data.total_count}
                        </Grid>
                    </Grid>
                    {!!data?.reserveInfo && <div style={{marginTop: '30px'}}>
                        <h2>Ritani Reserve Diamonds</h2>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <div>Reserved: {data.reserveInfo.reserved}</div>
                                <div>Reservable: {data.reserveInfo.reservable}</div>
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    onClick={() => downloadReserve(data.api_key)}
                                    style={{width: "200px", marginTop: '15px'}}
                                >
                                    {loadingReport ? <CircularProgress size={24} color="secondary" /> : 'Download Reserve'} 
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <input 
                                    type="file" 
                                    ref={fileRef}
                                    accept=".csv"
                                /><br />
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    onClick={() => uploadReserve(data.api_key)}
                                    style={{width: "200px", marginTop: '15px'}}
                                >
                                    {loadingReport ? <CircularProgress size={24} color="secondary" /> : 'Upload Reserve'} 
                                </Button>
                            </Grid>
                        </Grid>
                    </div>}
                </>
            }
            <BucketInfo supplier={selectedSupplier} />
        </div>
        
    )
}
